<script>
import {mapGetters} from "vuex";

export default {
  name: "PlayerBetAmount",
  props: {
    betAmount: {
      type: Number,
      required: true,
      default: 0,
    }
  },
  data() {
    return {
      shouldAnimate: false,
      initialRender: true,
      animationStyle: null
    };
  },
  computed: {
    ...mapGetters('game', ['roomInfo']),
    isWaitingNextTurn() {
      return this.roomInfo.isWaitingNextTurn || false;
    }
  },
  watch: {
    betAmount() {
      this.animateBet();
    },
    isWaitingNextTurn: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.initialRender = false;
          this.calculatePosition();
        });
      }
    }
  },
  methods: {
    calculatePosition() {
      const targetElement = this.$store.getters['game/potRef'];
      const betElement = this.$el;

      if (targetElement && betElement) {
        const targetRect = targetElement.getBoundingClientRect();
        const betRect = betElement.getBoundingClientRect();

        const translateX = targetRect.left - betRect.left + (targetRect.width / 2) - (betRect.width / 2);
        const translateY = targetRect.top - betRect.top + (targetRect.height / 2) - (betRect.height / 2);

        this.animationStyle = {
          opacity: 0,
          transformOrigin: 'center',
          transform: `translate(${translateX}px, ${translateY}px)`
        };
      }
    },
    animateBet() {
      if (!this.initialRender) {
        this.calculatePosition();
        this.shouldAnimate = true;
      }
    },
    animationEnded() {
      this.shouldAnimate = false;
    }
  }
}
</script>

<template>
  <div
    class="area_bet_point"
    :class="{ 'bet-animate': isWaitingNextTurn && shouldAnimate }"
    :style="isWaitingNextTurn && animationStyle"
    @animationend="animationEnded"
  >
    <div class="bet_point">{{ betAmount | formatCash }}</div>
  </div>
</template>

<style scoped lang="scss">
.area_bet_point {
  opacity: 1;
  transition: opacity 0.6s ease-in, transform 0.3s ease-out;
}
</style>